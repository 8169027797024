
import { Component, Vue } from 'vue-property-decorator'
import MapLocation from '@/components/mapLocation/Index.vue'
import { TableData } from '@/types/restaurant'

@Component({
  components: { MapLocation }
})

export default class RestaurantDetail extends Vue {
  private info: TableData | {}= {}

  created () {
    this.getDetail()
  }

  getDetail () {
    this.$axios.get<TableData>(this.$apis.restaurant.selectCateFoodById, {
      id: this.$route.params.id
    }).then(res => {
      this.info = res || {}
    })
  }
}
